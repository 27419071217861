@import 'components/mixins.scss';

#nprogress .spinner {
  display: none;
}

.ant-form-item {
  margin-bottom: 14px !important;
}

.ant-form-item-label {
  padding: 0 0 2px 0 !important;
}

.ant-table-cell {
  font-size: 1rem;
}

.ant-drawer-body {
  background-color: $gray-1;
}

code {
  padding: 5px 10px;
  display: inline-block;
}

.pin-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  justify-content: center;
}

.pin-field {
  width: 32px;
  height: 32px;
  font-size: 16px;
  text-align: center;
  outline: none;
  border-radius: 6px;
  border: 1px solid $dark-gray-1;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}

.pin-field-large {
  width: 36px;
  height: 36px;
  font-size: 18px;
  text-align: center;
  outline: none;
  border-radius: 6px;
  border: 1px solid $dark-gray-1;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}

.pin-field-complete {
  border: 1px solid $green;
  color: $green;

  &[disabled] {
    background: rgba($green, 0.1);
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.input-transparent {
  width: 100%;
  background-color: transparent;
  font-weight: 800;
  border: 0.1px dotted transparent;

  &:hover,
  &:focus,
  &:active {
    border: 0.4px dotted $dark-gray-2;
  }
}

.btn-min-padding {
  padding: 2px 2px 2px 2px;
}
